import LinkAction from 'components/shared/Routing/components/LinkAction/LinkAction';
import type { LinkActionProps } from 'components/shared/Routing/components/LinkAction/shared/types';
import styled from 'styled-components';
import { actionWrapperStyling } from '../shared/styles';
import type { ListItemType, Size } from '../shared/types';

const Anchor = styled(LinkAction)<Pick<ListItemType, 'size'>>`
    ${actionWrapperStyling}

    &, &:hover, &:focus {
        text-decoration: none;
    }
`;

type AnchorWrapperProps = { size?: Size } & LinkActionProps;

function AnchorWrapper(props: AnchorWrapperProps) {
    const { children, size, action, ...restProps } = props;

    return (
        <Anchor action={action} size={size} tabIndex={0} {...restProps}>
            {children}
        </Anchor>
    );
}

export default AnchorWrapper;
