import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import DangerouslySetInnerHTML from 'dangerously-set-html-content';
import QuickView from 'components/quickView';
import { fetchHTML } from 'modules/helpers/fetch';
import Spinner from 'modules/shared/circleSpinner';
import { breakpoint, sizes } from 'variables';
import { ColorNordicGrey4 } from 'autogen/design-tokens/tokens';
import type { LinkAction } from 'components/shared/Routing/components/LinkAction/shared/types';

const StyledQuickView = styled(QuickView)`
    ${breakpoint.up(sizes.md)} {
        max-width: min(calc(100% - 40px), 1200px);
        width: 100%;
    }
`;

const StyledSpinner = styled(Spinner)`
    margin: auto;
`;

const QuickViewArticleWrapper = styled.div`
    background-color: ${ColorNordicGrey4};
    overflow-y: auto;
    overscroll-behavior: contain;
    width: 100%;

    // Specific items in a QuickView article is hidden
    .hidden-quickview,
    .product-item__actions,
    .product-item__favourite {
        display: none;
    }
`;

interface IQuickViewArticle {
    linkAction: LinkAction;
}

function Article({ linkAction }: IQuickViewArticle) {
    const [quickViewArticle, setQuickViewArticle] = useState<string>();
    const articlePromise = useMemo(() => fetchHTML(linkAction.url), [linkAction]);
    const QuickViewArticleWrapperRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        (async () => {
            setQuickViewArticle(await articlePromise);
        })();
    }, [articlePromise]);

    useEffect(() => {
        // bind legacy modules
        (async () => {
            // product sliders
            const { default: ProductSlider } = await import('modules/slider/productSlider');
            if (QuickViewArticleWrapperRef.current) {
                ProductSlider.bind(QuickViewArticleWrapperRef.current);
            }

            QuickViewArticleWrapperRef.current?.querySelectorAll('[data-js="youtube:block"]').forEach(async (node) => {
                const { default: YouTube } = await import('views/page/block/youtube');
                const youTube = new YouTube(node);
                youTube.init();
            });

            QuickViewArticleWrapperRef.current?.querySelectorAll('[data-js="vimeo:block"]').forEach(async (node) => {
                const { default: Vimeo } = await import('views/page/block/vimeo');
                const vimeo = new Vimeo(node);
                vimeo.init();
            });
        })();
    }, [quickViewArticle]);

    return quickViewArticle ? (
        <QuickViewArticleWrapper ref={QuickViewArticleWrapperRef}>
            <DangerouslySetInnerHTML html={quickViewArticle} />
        </QuickViewArticleWrapper>
    ) : (
        <StyledSpinner size={50} timeout={200} />
    );
}

export default function QuickViewArticle({ quickViewId, ...restProps }: IQuickViewArticle & { quickViewId: string }) {
    return (
        <StyledQuickView quickViewId={quickViewId}>
            <Article {...restProps} />
        </StyledQuickView>
    );
}
