/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as Sorting from './trans-website-shared-sorting';
export * as AdvisorMenu from './trans-website-shared-advisormenu';
export * as ProductList from './trans-website-shared-productlist';
export * as Buttons from './trans-website-shared-buttons';
export * as ProductItem from './trans-website-shared-productitem';
export * as Error from './trans-website-shared-error';

export const MakeupAdvisor = 'Website.Shared.MakeupAdvisor';
export const RotateAndZoom = 'Website.Shared.RotateAndZoom';
export const WatchVideoNotAllowed = 'Website.Shared.WatchVideoNotAllowed';
export const AcceptCookieToWatch = 'Website.Shared.AcceptCookieToWatch';
export const Video = 'Website.Shared.Video';
export const Loading = 'Website.Shared.Loading';
export const Time = 'Website.Shared.Time';
export const Starting = 'Website.Shared.Starting';
export const Ending = 'Website.Shared.Ending';
export const CustomerService = 'Website.Shared.CustomerService';
export const ViewDetails = 'Website.Shared.ViewDetails';
export const HideDetails = 'Website.Shared.HideDetails';
export const Locked = 'Website.Shared.Locked';
export const Date = 'Website.Shared.Date';
export const ChooseDate = 'Website.Shared.ChooseDate';
export const Signup = 'Website.Shared.Signup';
export const OK = 'Website.Shared.OK';
export const Edit = 'Website.Shared.Edit';
export const Continue = 'Website.Shared.Continue';
export const Here = 'Website.Shared.Here';
export const Notice = 'Website.Shared.Notice';
export const LogIn = 'Website.Shared.LogIn';
export const FindStore = 'Website.Shared.FindStore';
export const SearchBrands = 'Website.Shared.SearchBrands';
export const Back = 'Website.Shared.Back';
export const SeeAllIn = 'Website.Shared.SeeAllIn';
export const Yes = 'Website.Shared.Yes';
export const No = 'Website.Shared.No';
export const Points = 'Website.Shared.Points';
export const CouldNotLoadPage = 'Website.Shared.CouldNotLoadPage';
export const Menu = 'Website.Shared.Menu';
export const ErrorInField = 'Website.Shared.ErrorInField';
export const Search = 'Website.Shared.Search';
export const Store = 'Website.Shared.Store';
export const AddOneToBasket = 'Website.Shared.AddOneToBasket';
export const AddMultipleToBasket = 'Website.Shared.AddMultipleToBasket';
export const ShowAll = 'Website.Shared.ShowAll';
export const SeeAll = 'Website.Shared.SeeAll';
export const ShowLess = 'Website.Shared.ShowLess';
export const ShowMore = 'Website.Shared.ShowMore';
export const DayShorthand = 'Website.Shared.DayShorthand';
export const HourShorthand = 'Website.Shared.HourShorthand';
export const MinuteShorthand = 'Website.Shared.MinuteShorthand';
export const SecondShorthand = 'Website.Shared.SecondShorthand';
export const Save = 'Website.Shared.Save';
export const Delete = 'Website.Shared.Delete';
export const Remove = 'Website.Shared.Remove';
export const Reset = 'Website.Shared.Reset';
export const Close = 'Website.Shared.Close';
export const Cancel = 'Website.Shared.Cancel';
export const Undo = 'Website.Shared.Undo';
export const Favorites = 'Website.Shared.Favorites';
export const MyProfile = 'Website.Shared.MyProfile';
export const ClubMatasPlus = 'Website.Shared.ClubMatasPlus';
export const Choose = 'Website.Shared.Choose';
export const GeneralExceptionError = 'Website.Shared.GeneralExceptionError';
export const GeneralVideoExceptionError = 'Website.Shared.GeneralVideoExceptionError';
export const MissingImage = 'Website.Shared.MissingImage';
export const NoThanks = 'Website.Shared.NoThanks';
export const Delivery = 'Website.Shared.Delivery';
export const LinkedCopied = 'Website.Shared.LinkedCopied';
export const ReadMore = 'Website.Shared.ReadMore';
export const ChooseAll = 'Website.Shared.ChooseAll';
export const BecomePlusMember = 'Website.Shared.BecomePlusMember';
export const ShopHere = 'Website.Shared.ShopHere';
export const Age = 'Website.Shared.Age';
export const GoBack = 'Website.Shared.GoBack';
export const NamePrefix = 'Website.Shared.NamePrefix';
export const StoreIdPrefix = 'Website.Shared.StoreIdPrefix';
export const News = 'Website.Shared.News';
export const MoreDetails = 'Website.Shared.MoreDetails';
export const PurchaseOver = 'Website.Shared.PurchaseOver';
export const PurchaseOverWithDisclaimer = 'Website.Shared.PurchaseOverWithDisclaimer';
export const Obs = 'Website.Shared.Obs';
