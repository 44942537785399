import type { JavascriptRequestDataModel } from 'autogen/swagger/recoil';

const JavascriptRequestDataContextObj = {};

export const setJavascriptRequestDataContext = (obj): void => {
    Object.entries(obj).forEach(([key, item]) => {
        JavascriptRequestDataContextObj[key] = item;
    });
};

const JavascriptRequestDataContext = (): JavascriptRequestDataModel | Record<string, never> =>
    JavascriptRequestDataContextObj;

export default JavascriptRequestDataContext();
