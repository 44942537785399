import type { UserContextViewModel } from 'autogen/swagger/recoil';

const userContextObj = {};

export const setUserContext = (obj): void => {
    Object.entries(obj).forEach(([key, item]) => {
        userContextObj[key] = item;
    });
};

const UserContext = (): UserContextViewModel | Record<string, never> => userContextObj;

export default UserContext();
