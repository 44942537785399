
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection ES6UnusedImports
        // noinspection JSUnusedGlobalSymbols
        import { useQuery, type UseQueryOptions, useMutation, type UseMutationOptions } from '@tanstack/react-query';
        import {
SearchStoreByAddress,
type SearchStoreByAddressSuccess,
type SearchStoreByAddressParams,
SearchStoreByCoordinates,
type SearchStoreByCoordinatesSuccess,
type SearchStoreByCoordinatesParams,
Validate,
type ValidateSuccess,
type ValidateParams,
_GetSelectedProduct,
type _GetSelectedProductSuccess,
type _GetSelectedProductParams,
SetUserPreferredStore,
type SetUserPreferredStoreSuccess,
type SetUserPreferredStoreParams,
GetProductStoreStockModel,
type GetProductStoreStockModelSuccess,
type GetProductStoreStockModelParams} from './index'

        
        
    export const SearchStoreByAddressQueryKey = 'store_SearchStoreByAddress';

    export function useSearchStoreByAddressQuery<TError = unknown>(params: SearchStoreByAddressParams, options?: Omit<UseQueryOptions<SearchStoreByAddressSuccess, TError, SearchStoreByAddressSuccess, [string, SearchStoreByAddressParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: SearchStoreByAddressSuccess | (() => SearchStoreByAddressSuccess);},  baseQueryKey = SearchStoreByAddressQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => SearchStoreByAddress(params), options);
        }
  
    
        
    export const SearchStoreByCoordinatesQueryKey = 'store_SearchStoreByCoordinates';

    export function useSearchStoreByCoordinatesQuery<TError = unknown>(params: SearchStoreByCoordinatesParams, options?: Omit<UseQueryOptions<SearchStoreByCoordinatesSuccess, TError, SearchStoreByCoordinatesSuccess, [string, SearchStoreByCoordinatesParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: SearchStoreByCoordinatesSuccess | (() => SearchStoreByCoordinatesSuccess);},  baseQueryKey = SearchStoreByCoordinatesQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => SearchStoreByCoordinates(params), options);
        }
  
    
        
      export function useValidateMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<ValidateSuccess, TError, {params: ValidateParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    Validate(params, ), options)}
  
    
        
    export const _GetSelectedProductQueryKey = 'store__GetSelectedProduct';

    export function use_GetSelectedProductQuery<TError = unknown>(params: _GetSelectedProductParams, options?: Omit<UseQueryOptions<_GetSelectedProductSuccess, TError, _GetSelectedProductSuccess, [string, _GetSelectedProductParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: _GetSelectedProductSuccess | (() => _GetSelectedProductSuccess);},  baseQueryKey = _GetSelectedProductQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => _GetSelectedProduct(params), options);
        }
  
    
        
      export function useSetUserPreferredStoreMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SetUserPreferredStoreSuccess, TError, {params: SetUserPreferredStoreParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SetUserPreferredStore(params, ), options)}
  
    
        
    export const GetProductStoreStockModelQueryKey = 'store_GetProductStoreStockModel';

    export function useGetProductStoreStockModelQuery<TError = unknown>(params: GetProductStoreStockModelParams, options?: Omit<UseQueryOptions<GetProductStoreStockModelSuccess, TError, GetProductStoreStockModelSuccess, [string, GetProductStoreStockModelParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetProductStoreStockModelSuccess | (() => GetProductStoreStockModelSuccess);},  baseQueryKey = GetProductStoreStockModelQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetProductStoreStockModel(params), options);
        }
  
    
    