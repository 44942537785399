/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as VTO from './trans-website-product-vto';
export * as Findation from './trans-website-product-findation';
export * as PriceInfo from './trans-website-product-priceinfo';
export * as Subscription from './trans-website-product-subscription';
export * as GwpOffer from './trans-website-product-gwpoffer';
export * as HazardLabel from './trans-website-product-hazardlabel';
export * as Instructions from './trans-website-product-instructions';
export * as Tabs from './trans-website-product-tabs';
export * as ProductVariants from './trans-website-product-productvariants';

export const Product360 = 'Website.Product.Product360';
export const MinimumSalesQuantityBuyButton = 'Website.Product.MinimumSalesQuantityBuyButton';
export const SeeVideo = 'Website.Product.SeeVideo';
export const VideoPlaceholderText = 'Website.Product.VideoPlaceholderText';
export const PlayingInXSeconds = 'Website.Product.PlayingInXSeconds';
export const AddToFavoriesAriaText = 'Website.Product.AddToFavoriesAriaText';
export const RemoveFromFavoriesAriaText = 'Website.Product.RemoveFromFavoriesAriaText';
export const RatingAriaText = 'Website.Product.RatingAriaText';
